<template>
  <div class="learning_center">
    <div class="contain">
      <img src="@/assets/image/bg-pic.png" alt="" />
      <span>功能待上线......</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LearningCenter',
  components: {},
  data() {
    return {}
  },
  created() {},
  methods: {}
}
</script>

<style lang="less" scoped>
.learning_center {
  width: 100%;
  .contain {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60vh;
    img {
      width: 282px;
      height: 216px;
    }
    span {
      color: #999;
    }
  }
}
</style>